let protocol, hostname, port, path, workspace = "00";
if (process.env.NODE_ENV === 'production') {
    protocol = '';
    hostname = '';
    port = '';
    path = process.env["VUE_APP_API_PATH"];
} else if (process.env.NODE_ENV === 'test') {
    protocol = '';
    hostname = '';
    port = '';
    path = '';
} else if (process.env.NODE_ENV === 'development') {
    // http://hangchabp.com/hcde
    // protocol = 'http';
    // hostname = '192.168.31.170';
    // port = 9520;
    // path = 'hcbmtest';
    // protocol = 'http';
    // hostname = 'hangchabp.com';
    // port = 80;
    // path = 'hcde';
    protocol = 'http';
    hostname = '69.234.206.63';
    port = 80;
    path = 'mallts';
}
if (!path) {
    throw new Error('path未配置')
}

exports.REST_PROTOCOL = protocol;
exports.REST_HOSTNAME = hostname;
exports.REST_PORT = port;
exports.REST_PATH = path;
exports.WORKSPACE = workspace;

exports.REST_ORI = `${this.REST_PROTOCOL}://${this.REST_HOSTNAME}:${this.REST_PORT}`