<template>
    <div class="product w1200">
        <div class="title">
            <h2 class="t-main">{{ $t('product.title') }}</h2>
            <p class="t-sub">{{ $t('product.advantage') }}</p>
        </div>
        <ul class="p-series">
            <li class="series-item"
                v-for="(item, idx) in list"
                :key="idx"
                @click="gotoDetail(item.id)"
            >
                <div class="p-img-box">
                    <img :src="item.image || defaultPng"/>
                </div>

                <div class="p-detail">
                  <div class="price-wrap" v-if="isLogin">
                    <span class="price-symbol">{{ fmtFcode(item.fcode) }}</span>
                    <span class="price-amount">{{ item.upric | formatMoney }}</span>
                  </div>
                  <div class="p-other">
                    <div class="p-left">
                      <p class="p-name" v-if="language==='en'">{{ item.name_en }}</p>
                      <p class="p-name" v-else>{{ item.name_cn }}</p>
                      <p class="p-code">{{ item.figurenum }}</p>
                    </div>
                    <div class="add" @click.stop="add2Cart(item.id, item.type)">+</div>
                  </div>
                </div>
            </li>
        </ul>
        <hc-page :page-num="pageNum"
                 :page-size="pageSize"
                 :total-size="totalSize"
                 @gotoPage="gotoPage"
        />
    </div>
</template>
<script>
import HcPage from "@/components/busi/page/hc-page.vue";
import {getProductList} from "@/api/hc/product";
import {addToCart} from "@/api/hc/cart";
import Mixins from "@/common/core/mixins";
import {isNotBlank} from "@/common/utils";
import VF from "@/common/core/valueFormatter";

export default {
    name: "product-list",
    components: {HcPage},
    mixins: [Mixins],
    created() {
        this.loadData();
    },
    data() {
        return {
            pageNum: 1,
            pageSize: 8,
            totalSize: 0,
            list: []
        }
    },
    methods: {
        getKeyword() {
            let {keyword} = this.$route.query;
            if (isNotBlank(keyword)) {
                return decodeURIComponent(keyword);
            }
            return null;
        },
        loadData(pageNum = 1) {
            getProductList(this.getKeyword(), pageNum, this.pageSize)
                .then(({pageable, list}) => {
                    this.renderList(pageable, list);
                })
        },
        renderList({pageNum, total}, list) {
            this.pageNum = pageNum;
            this.totalSize = total;
            this.list = null;
            this.list = list;
        },
        gotoPage(pageNum) {
            this.loadData(pageNum);
        },
        add2Cart(id, type) {
            addToCart(id, type)
                .then(() => {
                    this.$toast(this.$t('cart.addSuccess'));
                    // 刷新购物车数量
                    this.$store.dispatch('count/getCartCount');
                })
        },
        gotoDetail(id) {
            this.$router.push({
                path: '/item',
                query: {
                    id
                }
            })
        },
        fmtFcode(fcode) {
            return VF.getFcodeSymbol(fcode);
        }
    }
}
</script>

<style scoped lang="scss">

</style>