<template>
    <div class="">
        <hc-header/>
        <div class="content">
            <div class="product-info w1200">
                <div class="product-main" v-if="product">
                    <div class="mainpic swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item,index) in product.imageList" :key="index">
                                <img :src="item || defaultPng"/>
                            </div>
                        </div>
                        <!--   导航按钮   -->
                        <div class="swiper-button-prev swiper-button-black"></div>
                        <div class="swiper-button-next swiper-button-black"></div>
                    </div>
                    <div class="iteminfo">
                        <h2 class="main-title">
                            <span>{{ product.name_en }}</span>
                            <span></span>
                        </h2>
                        <p class="product-main-num">
                            <span>{{ $t('product.figurenum') }}：</span>
                            <span>{{ product.figurenum }}</span>
                        </p>
                        <div class="main-price" v-if="isLogin">{{ product.upric | formatMoney(product.fcode) }}</div>
                        <router-link to="/login" v-else>
                            <div class="main-price">{{ $t('product.login2view') }}</div>
                        </router-link>
                        <div class="product-basic">
                            <h4>{{ $t('product.goodsInfo') }}</h4>
                            <div class="basic-info">
                                <p>{{ $t('product.code') }}：<span>{{ product.code }}</span></p>
                                <p>{{ $t('product.stock') }}：<span>{{ product.actuallibno }}</span></p>
                                <p>{{
                                        $t('product.estarridate')
                                    }}：<span>{{
                                            product.estarridate
                                        }}&nbsp;&nbsp;{{ product.estarridate ? $t('product.day') : '' }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="choose-btn">
                            <sn-number-box v-model="qtc" :min="1"/>
                            <button class="add-to-cart" @click="addCart">{{ $t('cart.add') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hc-footer/>
    </div>
</template>

<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import Mixins from "@/common/core/mixins";
import {getProductDetail} from "@/api/hc/product";
import SnNumberBox from "@/components/common/numberbox/sn-number-box.vue";
import {addToCart} from "@/api/hc/cart";
import Swiper from "swiper";

export default {
    name: "HomePage",
    mixins: [Mixins],
    components: {SnNumberBox, HcFooter, HcHeader},
    created() {
        this.loadData();
    },
    data() {
        return {
            product: {},
            qtc: 1
        }
    },
    // mounted() {
    //     this.initSwiper();
    // },
    methods: {
        //滚动
        initSwiper() {
            const swiper = new Swiper('.swiper-container', {
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            })
        },
        async loadData() {
            const {id} = this.$route.query;
            const {product} = await getProductDetail(id);
            this.product = product;
            this.$nextTick(() => {
                this.initSwiper();
            })
        },
        addCart() {
            addToCart(this.product.id, this.product.type, this.qtc)
                .then(() => {
                    this.$toast(this.$t('cart.addSuccess'));
                    // 刷新购物车数量
                    this.$store.dispatch('count/getCartCount');
                });
        }
    }
}
</script>

<style scoped>
page {
    background: #f1f1f1;
}

.swiper-button-prev, .swiper-button-next {
    opacity: 0.4;
    height: 24px;
    background-size: 27px 24px;
}
</style>